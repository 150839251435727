@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');

.form {
  @apply grid grid-cols-1 gap-4;
}

.input {
  @apply bg-gray-200 text-gray-900 p-2 rounded-md;
}

.button {
  @apply bg-black text-white py-2 px-4 rounded-md;
}

.todos {
  @apply mt-4;
}

.todo {
  @apply bg-gray-100 p-2 my-2 rounded-md;
}

.container {
  @apply max-w-4xl;
}

.font-kanit {
  font-family: 'Kanit', sans-serif;
}